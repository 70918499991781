<template>
    <div class="page">
        <div class="title">审批模板分组</div>

        <el-divider></el-divider>

        <!-- filter -->
        <div class="filter_box">
            <div class="filter_search"></div>
            <div class="filter_btns">
                <div class="custom_button no_select" @click="add">
                    添加分组
                </div>
            </div>
        </div>

        <!-- table -->
        <el-table
            id="table"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column prop="title" label="分组名称"></el-table-column>
            <el-table-column prop="sort_num" label="排序"></el-table-column>
            <el-table-column label="操作" fixed="right">
                <template v-slot:default="scope">
                    <div class="operations">
                        <div @click="edit(scope.row)">编辑</div>
                        <div @click="del(scope.row.id)">删除</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            :title="form.id ? '编辑分组' : '添加分组'"
            width="40%"
            :visible.sync="dialogFormVisible"
        >
            <el-form :model="form" ref="form" :rules="rules">
                <el-form-item label="分组名称" prop="title">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort_num">
                    <el-input v-model="form.sort_num" type="number"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            search: {
                keyword: "",
                date: "",
            },
            tableData: [],
            page: {
                size: 15,
                page: 1,
                total: 0,
            },
            dialogFormVisible: false,
            form: {
                title: "",
                sort_num: "0",
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: "请填写分组名称",
                        trigger: "blur",
                    },
                ],
                sort_num: [
                    {
                        required: true,
                        message: "请填写排序",
                        trigger: "blur",
                    },
                ],
            },
            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        ...mapActions("attendance", [
            "getApproverCateList",
            "postAddApproverCate",
            "postEditApproverCate",
            "getDelApproverCate",
        ]),

        loadData() {
            let loading = this.$loading();
            this.getApproverCateList({
                page: this.page.page,
            })
                .then((res) => {
                    console.log(res);
                    this.tableData = res.data.data.list;
                    this.page.total = res.data.data.total;
                    this.$nextTick(() => {
                        loading.close();
                    });
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                    this.$nextTick(() => {
                        loading.close();
                    });
                });
        },

        add() {
            this.form = {
                title: "",
                sort_num: "0",
            };
            this.dialogFormVisible = true;
        },

        edit(row) {
            this.form = {
                id: row.id,
                title: row.title,
                sort_num: row.sort_num,
            };
            this.dialogFormVisible = true;
        },

        del(id) {
            this.$confirm("此操作不可逆转，确定删除吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let loading = this.$loading();
                this.getDelApproverCate({ id })
                    .then((res) => {
                        console.log(res);
                        loading.close();
                        this.$message.success(res.data.msg);
                        this.loadData();
                    })
                    .catch((e) => {
                        console.log(e);
                        loading.close();
                        this.$message.error(e.msg);
                    });
            });
        },

        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.dialogFormVisible = false;
                    let loading = this.$loading();
                    console.log(this.form);
                    if (this.form.id) {
                        this.postEditApproverCate(this.form)
                            .then((res) => {
                                console.log(res);
                                loading.close();
                                this.$message.success(res.data.msg);
                                this.loadData();
                            })
                            .catch((e) => {
                                console.log(e);
                                loading.close();
                                this.$message.error(e.msg);
                            });
                    } else {
                        this.postAddApproverCate(this.form)
                            .then((res) => {
                                console.log(res);
                                loading.close();
                                this.$message.success(res.data.msg);
                                this.loadData();
                            })
                            .catch((e) => {
                                console.log(e);
                                loading.close();
                                this.$message.error(e.msg);
                            });
                    }
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.goods_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;

    .goods_item {
        width: calc(50% - 82px);
        background: #ffffff;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        padding: 40px 35px;

        .goods_item_head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 15px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40px;
                height: 3px;
                background: #4db9d5;
            }

            .goods_item_head_title {
                font-size: 20px;
                color: #1c2438;
            }

            .goods_item_head_price {
                > span:nth-child(1) {
                    font-size: 14px;
                    color: #f94d09;
                }

                > span:nth-child(2) {
                    font-size: 26px;
                    color: #f94d09;
                }

                > span:nth-child(3) {
                    font-size: 14px;
                    color: #1c2438;
                    position: relative;
                    bottom: 2px;
                }
            }
        }
    }

    .goods_item_content {
        font-size: 14px;
        color: #1c2438;
        line-height: 30px;
        margin-top: 50px;
    }
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .el-date-editor {
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }
    }

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
